.display-image {
    max-width: 100%;
}

.file-upload {
    background-color: #e9ecef;
}

.marker-actions-container {
    margin-top: 7px;
    padding: 7px 0px;
    border-top: 1px solid #00146d;
}

.marker-info-description {
    margin: 10px 0;
    max-height: 150px;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.popup-button {
    padding: 3px 10px;
    background-color: #00146d;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-right: 5px;
}

.popup-button-delete {
    background-color: #ff0000;
}

.popup-container {
    width: 300px;
}

.popup-form-input {
    width: calc(100% - 20px);
    font-family: "Arial", sans-serif;
}

.popup-image {
    max-width: 100%;
    max-height: 200px;
    animation: fadein 0.2s ease-in;
    cursor: pointer;
}
.popup-image:hover {
    filter: brightness(1.2);
}

.popup-input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #00146d;
    border-radius: 4px;
}

.popup-textarea {
    font-family: "Arial", sans-serif;
    min-height: 100px;
    overflow-y: scroll;
}

.popup-title {
    font-size: 1.2em;
    font-weight: 700;
    text-align: center;
    color: #00146d;
    padding-left: 5px;
    margin-bottom: 4px;
}

.votes-container {
    justify-content: flex-start;
}

.vote-element {
    color: #666666;
    padding-right: 4px;
}

@media (max-width: 495px) {

    .popup-button {
        font-size: 1.3em;
        padding: 8px 15px;
    }

}
