body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.debug {
  z-index: 2000;
  background-color: rgba(230,255,255,230);
  font-size: 0.8em;
  position: fixed;
  height: 20px;
  bottom: 10px;
  left: 10px;
  right: 10px;

}

.back-arrow {
  /*position: absolute;*/
  /*left:20px;*/
  /*top: 20px;*/
}
.back-arrow:hover {
  opacity: 0.8;
  cursor: pointer;
}

.menu-logo {
  height: 32px;
}

/* Form inputs ----------------------------------------------------------------------------------- */

.form-control {
  width: 100%;
}

.form-input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #787878;
  border-radius: 4px;
  font-size: 1em;
  font-family: "Arial", sans-serif;
}

.button {
  color: #ffffff;
  background-color: #00146d;
  cursor: pointer;
  min-width: 200px;
  max-width: 200px;
  padding: 14px 20px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 1em;
  text-decoration: none;
}

.btn-sm {
  padding: 3px 10px;
  min-width: 100px;
  margin: 0 5px;
}

.button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button:hover {
  background-color: #72c4c0;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
