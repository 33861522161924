h3 {
  font-size: 1.3em !important;
  font-weight: bold !important;
  color: #00146d;
}
h5 {
    font-size: 1em !important;
    color: #00146d;
}

h6 {
  font-size: 1.1em !important;
  font-weight: bold !important;
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: icon-spin infinite 20s ease-in;
  }
}

.header {
  background-color: #ffffff;
  padding: 0 20px;
  color: darkgrey;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cdcdcd;
}

.header-menu {
  display: block;
}

.toggler {
  display: none;
  cursor: pointer;
}
.toggler:hover {
  opacity: 0.8;
}

.popup-menu {
  position: absolute;
  width: 100%;
  top: 48px;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 5000;
  display: flex;
  flex-direction: column;
}

.popup-menu a {
  margin: 14px 0 14px 0;
}

@media (max-width: 495px) {
  .header-menu {
    display: none;
  }

  .toggler {
    display: block;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-collapse-icon {
  display: none;
}

.user-icon {
  margin-top:20px;
  padding: 10px;
  border-radius: 40px;
  width: 50px;
  height: 50px;
  border: 3px solid #222222;
}

@media (max-width: 400px) {
  .header-collapse {
    display: none;
  }
  .header-collapse-icon {
    display: block
  }
}

.App-link {
  color: #61dafb;
  color: #61dafb;
}

@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menuLink {
  margin: 0 10px;
  text-decoration: none;
}

.main-logo {
  width: 100%;
  max-width: 500px;
}
