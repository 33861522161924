.map {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
}

.add-marker-form-container {
    position: absolute;
    top: 56px;
    width: 100%;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 4000;
}

.add-marker-form {
    background-color: #ffffff;
    border: 1px solid #cdcdcd;
    width: 100%;
    max-width: 420px;
    text-align: left;
    padding: 5px;
}

.button-panel {
    z-index: 3000;
}

.btn-primary {
    background-color: #00146d !important;
}
.btn-primary:hover {
    opacity: 0.8 !important;
}

.button-panel-message {
    margin-top:5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
    min-height: 24px;
    background-color: rgba(255,255,255,0.8);
    padding: 4px 20px;
    border-radius: 4px;
}

.button-back {
    padding: 3px 10px;
    background-color: #343434;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-right: 5px;
}

.map-back-arrow {
    cursor: pointer;
}

.map-back-arrow:hover {
    filter: brightness(1.2);
}

.sign-up-button {
    text-decoration: none;
    color: #ffffff;
    padding: 7px 14px;
    margin: 7px;
    border-radius: 7px;
    background-color: #00146d;
}

.questionnaire-info-panel {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
    min-height: 24px;
    border-radius: 4px;
}
.questionnaire-info-panel > h2 { margin: 5px 10px; }

.sign-up-button:hover {
    background-color: #61dafb;
}

.add-button {
    color: #ffffff;
    padding: 7px 14px;
    margin: 7px;
    border-radius: 7px;
}

.add-button:hover {
    color: #ababab;
    filter: brightness(1.2);
    cursor: pointer;
}

.add-button-label {
    margin-left: 14px;
}
.add-button-label-mobile {
    display: none;
}

.leaflet-control-layers-base {
    text-align: left;
}

@media (max-width: 768px) {
    .add-button {
        flex-direction: column;
        min-height: 80px;
        margin-top: 12px;
        max-width: 120px;
    }

    .btn-sm {
        font-size: 0.8em;
        padding: 2px 4px;
        min-width: auto;
    }

    .signup-panel {
        display: flex;
        flex-direction: column;
    }

    .add-button-label {
        display: none;
    }

    .add-button-label-mobile {
        font-size: 0.7em;
        text-transform: uppercase;
        display: block;
    }

    .questions-answered { display: none; }
    .questionnaire-info-panel { background-color: white; }
    .questionnaire-info-panel > h2 { font-size: 0.9em; }

    .question-panel {
        margin-top: 0;
    }
}

@media (max-width: 495px) {

    .add-marker-form {
        margin-right: 20px;
        width: calc(100% - 40px);
        max-width: 1000px;
    }

    .add-marker-form-container {
        left: 10px;
        right: 20px;
    }

    .leaflet-control-zoom {
        margin-bottom: 42px !important;
    }
}
