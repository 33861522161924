
.questionnaire-info-panel h2 {
    font-size: 1.2em;
}

.question-panel {
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: left;
    background-color: white;
    padding: 10px 15px;
    z-index: 100;
    overflow-y: scroll;
}

.question-panel img {
    max-width: 90%;
}

.placecheck-question {
    padding: 10px;
    background-color: #c2f2fc;
    border-radius: 7px;
}

textarea {
    margin: 10px 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    padding: 7px;
}

.questionnaire-submit {
    position: absolute;
    top: 148px;
    left: 5%;
    right: 5%;
    bottom: 5%;
    text-align: left;
    background-color: white;
    padding: 10px 15px;
    z-index: 3001;
    border: 1px solid #00146d;
}

.flex-collapse {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

@media(max-width: 576px) {
    .flex-collapse {
        display: block;
    }
}