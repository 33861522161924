
.comments-list {
    z-index: 3500;
    text-align: left;
}

.comment-container {
    width: 100%;
    max-width: 1400px;
    /*margin-top: 14px;*/
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 4px;
    /*box-shadow: -1px 1px 4px 4px rgba(0,0,80,0.15);*/
    /*background-color: #efefff;*/
    border-top: 1px solid #cdcdcd;
    /*box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 6px 0 rgba(0, 0, 0, 0.1);*/

}

.comment-icon {
    min-width: 48px;
    display: flex;
    justify-content: center;
}

.comment-image {
    height: 32px;
}

.comment-name {
    font-weight: bold;
    min-width: 250px;
    max-width: 250px;
    margin-right: 10px;
}
.comments-button {
    cursor: pointer;
}
.comments-button:hover {
    opacity: 0.8;
}
.comment-description {
    min-width: 650px;
    max-width: 650px;
    margin-right: 7px;
}

.comment-date {
    font-size: 0.8em;
}

@media(max-width: 1024px) {

    .comment-date {
        text-transform: uppercase;
        font-size: 0.7em;
    }

    .comment-description {
        min-width: auto;
        padding-left: 48px;
        font-size: 0.8em;
    }
}

@media(max-width: 767px) {
    .comment-container {
        flex-wrap: wrap;
    }

}