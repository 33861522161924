.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2000;
}

.login-form {
    padding: 20px;
    width: 100%;
    max-width: 350px;
    text-align: left;
}

.login-logo-container {
    text-align: center;
    animation: fadein 1s ease-in-out;
}

.login-logo {
    width: 300px;
}

.login-forgotten-password-link {
    font-size: 0.8em;
    text-decoration: none;
    margin-top:30px;
}

.login-notes {
    font-size: 0.8em;
    margin-top: 20px;
}

.login-forgotten-password {
    margin-top: 30px;
}

.login-error-message {
    margin-top: 10px;
    text-align: center;
    color: #ff0000;
    animation: fadein 0.2s ease-in-out;
}

.login-success-message {
    margin-top: 10px;
    text-align: center;
    color: #447711;
    animation: fadein 0.2s ease-in;
}

@media (max-width: 495px) {
    .login-form {
        left: 0;
        right: 0;
        margin-right:20px;
        width: calc(100% - 40px);
    }
}
